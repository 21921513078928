import { Formik, FormikConfig, FormikProps, FormikTouched } from 'formik';
import React from 'react';
import { Form as BsForm } from 'react-bootstrap';
import { BaseProps, getBaseProps } from './util';

export interface Props<FormValues> extends FormikConfig<FormValues>, BaseProps<HTMLFormElement> {
  setInitialTouched?: boolean;
  inline?: boolean;
  showValidFeedback?: boolean;
  children: (formik: FormikProps<FormValues>) => React.ReactNode;
}

export interface FormContextProps {
  showValidFeedback?: boolean;
}

export const FormContext = React.createContext<FormContextProps>({
  showValidFeedback: undefined,
});

export const Form = <FormValues extends object>(props: Props<FormValues>) => {
  const { baseProps, ...otherProps } = getBaseProps(props, {});
  const { setInitialTouched, inline, children, ...formikProps } = otherProps;

  const context = {
    showValidFeedback: props.showValidFeedback,
  };

  const getInitialTouched = React.useCallback((obj: object) => {
    const result: FormikTouched<any> = {};
    for (const [key, value] of Object.entries(obj)) {
      if (value instanceof Object) {
        result[key] = getInitialTouched(value);
      } else {
        result[key] = value !== undefined && value !== '';
      }
    }
    return result;
  }, []);

  return React.useMemo(
    () => (
      <Formik<FormValues>
        {...formikProps}
        initialTouched={setInitialTouched ? getInitialTouched(props.initialValues) : undefined}
      >
        {(formik) => (
          <BsForm
            {...baseProps}
            noValidate
            inline={props.inline}
            onSubmit={formik.handleSubmit}
            onReset={formik.handleReset}
          >
            <FormContext.Provider value={context}>{children(formik)}</FormContext.Provider>
          </BsForm>
        )}
      </Formik>
    ),
    [
      baseProps,
      children,
      context,
      formikProps,
      getInitialTouched,
      props.initialValues,
      props.inline,
      setInitialTouched,
    ],
  );
};

Form.defaultProps = {};
