import { AxiosError } from 'axios';
import React from 'react';
import { Alert } from 'react-bootstrap';
import { request, Request, BaseProps } from './util';

interface Props<T> extends Request, BaseProps<HTMLDivElement> {
  children?: (data: T) => React.ReactNode;
}

const Query = <T extends object>(props: React.PropsWithChildren<Props<T>>) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<T>();
  const [error, setError] = React.useState<AxiosError>();

  React.useEffect(() => {
    request<T>({
      url: props.url,
      method: props.method,
      params: props.params,
      data: props.data,
    })
      .then((response) => {
        setData(response.data);
      })
      .catch((axiosError: AxiosError) => {
        setError(axiosError);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [props.url, props.method, props.params, props.data]);

  return React.useMemo(
    () => (
      <>
        {loading ? (
          <Alert variant="light">Loading...</Alert>
        ) : error ? (
          <Alert variant="danger">
            ERROR {error.response?.status}
            <br />
            {error.message}
          </Alert>
        ) : data ? (
          props.children ? (
            props.children.call(undefined, data)
          ) : (
            <pre>{JSON.stringify(data, null, 2)}</pre>
          )
        ) : (
          <Alert variant="danger">Query error</Alert>
        )}
      </>
    ),
    [data, error, loading, props.children],
  );
};

Query.defaultProps = {};
export default Query;
