import { Breakpoint } from "simoneconti-react/src/interfaces";

export enum BreakpointEnum {
  XS = 0,
  SM = 576,
  MD = 768,
  LG = 992,
  XL = 1200,
}

export enum BreakpointContainerEnum {
  XS = 545,
  SM = 540,
  MD = 720,
  LG = 960,
  XL = 1140,
}

export const gridGutterWidth = 30;

export const breakpoints: Breakpoint[] = [
  {
    name: 'xs',
    viewport: BreakpointEnum.XS,
    container: BreakpointContainerEnum.XS,
  },
  {
    name: 'sm',
    viewport: BreakpointEnum.SM,
    container: BreakpointContainerEnum.SM,
  },
  {
    name: 'md',
    viewport: BreakpointEnum.MD,
    container: BreakpointContainerEnum.MD,
  },
  {
    name: 'lg',
    viewport: BreakpointEnum.LG,
    container: BreakpointContainerEnum.LG,
  },
  {
    name: 'xl',
    viewport: BreakpointEnum.XL,
    container: BreakpointContainerEnum.XL,
  },
];
