import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown, faChevronUp, faDownload, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import DownloadPage from './DownloadPage';
import EventsRouter from './EventsRouter';
import Header from './Header';
import Homepage from './Homepage';
import ImageResizerPage from './ImageResizerPage';
import JobInterviewQuestions from './JobInterviewQuestions';
import Meals from './Meals';
import * as storage from './storage';

library.add(faPlus, faChevronUp, faChevronDown, faTimes, faDownload);

export interface ContextProps {
  userContext: {
    apiKey?: string;
    handleLogin: (apiKey: string, remember: boolean, fromStorage: boolean) => void;
    handleLogout: () => void;
    isLogged: () => boolean;
  };
}

export const Context = React.createContext<ContextProps>({
  userContext: {
    apiKey: undefined,
    handleLogin: () => undefined,
    handleLogout: () => undefined,
    isLogged: () => false,
  },
});

function App() {
  const [apiKey, setApiKey] = React.useState<string | undefined>(restoreLogin());

  const context = {
    userContext: {
      apiKey: apiKey,
      handleLogin: handleLogin,
      handleLogout: handleLogout,
      isLogged: isLogged,
    },
  };

  return React.useMemo(
    () => (
      <BrowserRouter>
        <Context.Provider value={context}>
          <Header />
          <main>
            <Switch>
              <Route path="/image-resizer">
                <ImageResizerPage />
              </Route>
              <Route path="/events">
                <EventsRouter />
              </Route>
              <Route path="/download">
                <DownloadPage />
              </Route>
              <Route path="/job-interview-questions">
                <JobInterviewQuestions />
              </Route>
              <Route path="/meals">
                <Meals />
              </Route>
              <Route path="/">
                <Homepage />
              </Route>
            </Switch>
          </main>
        </Context.Provider>
      </BrowserRouter>
    ),
    [context],
  );

  function handleLogin(apiKeyParam: string, remember: boolean, fromStorage: boolean) {
    if (!fromStorage) {
      setApiKey(apiKeyParam);
    }
    if (remember) {
      storage.apiKey.set(apiKeyParam);
    } else {
      storage.apiKey.remove();
    }
    setApiKeyToAxiosHeaders(apiKeyParam);
  }

  function handleLogout() {
    setApiKey(undefined);
    storage.apiKey.remove();
    removeApiKeyFromAxiosHeaders();
  }

  function isLogged() {
    return apiKey !== undefined;
  }

  function restoreLogin() {
    const storedApiKey = storage.apiKey.get();
    if (storedApiKey) {
      handleLogin(storedApiKey, true, true);
    }
    return storedApiKey;
  }

  function setApiKeyToAxiosHeaders(apiKeyParam: string) {
    axios.defaults.headers.common['API-Key'] = apiKeyParam;
  }

  function removeApiKeyFromAxiosHeaders() {
    delete axios.defaults.headers.common['API-Key'];
  }
}

export default App;
