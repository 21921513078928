import React from 'react';
import Page from './Page';

interface Props {}

const Homepage = (props: Props) => {
  return React.useMemo(() => <Page></Page>, []);
};

Homepage.defaultProps = {};
export default Homepage;
