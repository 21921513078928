/**
 * String
 */
export function getString(key: string): string | undefined {
  return getItem(key);
}
export function setString(key: string, value: string) {
  return setItem(key, value);
}

/**
 * Boolean
 */
export function getBoolean(key: string): boolean {
  const value = getItem(key);
  if (value && JSON.parse(value)) {
    return true;
  } else {
    return false;
  }
}
export function setBoolean(key: string, value: boolean) {
  return setItem(key, JSON.stringify(value));
}

/**
 * Funzioni interne
 */
function getItem(key: string): string | undefined {
  return window.localStorage.getItem(key) || undefined;
}
function setItem(key: string, value: string) {
  window.localStorage.setItem(key, value);
}
export function removeItem(key: string) {
  window.localStorage.removeItem(key);
}


