import axios, { AxiosError, AxiosResponse, Method } from 'axios';
import classNames from 'classnames';
import { FormikHelpers } from 'formik';
import moment from 'moment';
import 'moment/locale/it';
import qs from 'qs';
import React from 'react';
import { Table } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown/with-html';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coy as syntaxHighlighterStyle } from 'react-syntax-highlighter/dist/esm/styles/prism';
import breaks from 'remark-breaks';
import * as yup from 'yup';
import { Breakpoint, EventDate } from './interfaces';
import Picture from './Picture';

export interface BaseProps<T> {
  className?: string;
  id?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<T>;
}

export function getBaseProps<T extends HTMLElement, Props extends BaseProps<T>>(
  props: Props,
  newProps: { className?: string; style?: React.CSSProperties },
) {
  const { className, id, style, onClick, ...otherProps } = props;
  const baseProps: BaseProps<T> = {
    className: props.className,
    id: props.id,
    style: props.style,
    onClick: props.onClick,
  };
  if (newProps.className) {
    baseProps.className = classNames(baseProps.className, newProps.className);
  }
  if (newProps.style) {
    baseProps.style = { ...baseProps.style, ...newProps.style };
  }
  return {
    baseProps: baseProps,
    ...otherProps,
  };
}

export const useWindowSize = (wait?: number) => {
  const [size, setSize] = React.useState(getSize());
  const timeoutId = React.useRef<NodeJS.Timeout>();

  const resizeListener = React.useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(
      () => {
        setSize(getSize());
      },
      wait !== undefined ? wait : 100,
    );
  }, [wait]);

  React.useEffect(() => {
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [resizeListener]);

  function getSize() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  return size;
};

export const useWindowScrollInfo = (wait?: number) => {
  const [scrollInfo, setScrollInfo] = React.useState(getScrollInfo());
  const timeoutId = React.useRef<NodeJS.Timeout>();

  const scrollListener = React.useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(
      () => {
        setScrollInfo(getScrollInfo());
      },
      wait !== undefined ? wait : 100,
    );
  }, [wait]);

  React.useEffect(() => {
    window.addEventListener('scroll', scrollListener);
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, [scrollListener]);

  function getScrollInfo() {
    return {
      scrollPosition: window.pageYOffset,
      scrolledToBottom: window.innerHeight + window.pageYOffset >= document.body.offsetHeight,
    };
  }
  return scrollInfo;
};

export const confirm = (message: string) =>
  new Promise((resolve, reject) => (window.confirm(message) ? resolve(true) : reject(false)));

export const isFormFieldRequired = (name: string, validationSchema: yup.ObjectSchema) =>
  formFieldHasTest(name, validationSchema, 'required');

const formFieldHasTest = (name: string, validationSchema: yup.ObjectSchema, hasActiveTest: string): boolean => {
  const schemaDescription = validationSchema.describe();
  const fields = schemaDescription.fields as any;
  const fieldNameSplitted = name.split('.').join('.fields.').split('.');
  const field = getNestedObject(fields, fieldNameSplitted);
  if (field !== undefined) {
    for (const test of field.tests) {
      if (test.name === hasActiveTest) {
        return true;
      }
    }
  }
  return false;
};

const getNestedObject = (object: any, path: string[]) =>
  path.reduce(
    (accumulator, currentValue) =>
      accumulator && accumulator[currentValue] !== 'undefined' ? accumulator[currentValue] : undefined,
    object,
  );

const formatDate = (date: Date, format: string): string => moment(date).format(format);
const parseDate = (date: string, format: string): Date => moment(date, format).toDate();

export const formatDateForInput = (date?: Date): string => (date ? formatDate(date, 'YYYY-MM-DD') : '');

export const parseDateFromInput = (date?: string): Date | undefined =>
  date ? parseDate(date, 'YYYY-MM-DD') : undefined;

export function getFilenameWithoutExtension(filename: string) {
  return filename.split('.').slice(0, -1).join('.');
}

export function getFileExtension(filename: string): string {
  return filename.split('.').pop()!;
}

export interface Request {
  url: string;
  method: Method;
  params?: any;
  data?: object;
  formUrlEncoded?: boolean;
  formikHelpers?: FormikHelpers<any>;
  log?: boolean;
}

export function request<T>(props: Request): Promise<AxiosResponse<T>> {
  const promise = new Promise<AxiosResponse<T>>((resolve, reject) => {
    axios
      .request<T>({
        ...props,
        data: props.formUrlEncoded ? props.data && qs.stringify(props.data) : props.data,
        timeout: 5000,
      })
      .then((response) => {
        if (props.log) {
          window.console.log(response);
        }
        if (props.formikHelpers) {
          props.formikHelpers.setSubmitting(false);
        }
        resolve(response);
      })
      .catch((axiosError: AxiosError) => {
        if (props.log) {
          window.console.log(axiosError);
        }
        if (axiosError.response?.data.message) {
          alert(axiosError.response?.data.message);
        } else if (axiosError.response?.data) {
          alert(axiosError.response?.data);
        } else if (axiosError.response) {
          alert(axiosError.response);
        } else {
          alert(axiosError);
        }
        if (props.formikHelpers) {
          props.formikHelpers.setSubmitting(false);
        }
        reject(axiosError);
      });
  });
  return promise;
}

export function setApiKeyToAxiosHeaders(apiKeyParam: string) {
  axios.defaults.headers.common['API-Key'] = apiKeyParam;
}

export function removeApiKeyFromAxiosHeaders() {
  delete axios.defaults.headers.common['API-Key'];
}

export function formatEventDate(date?: EventDate): string {
  moment.locale('it');
  let result = '';
  if (date) {
    if (date.day) {
      result += `${date.day} `;
    }
    if (date.month) {
      result += `${moment()
        .month(date.month - 1)
        .format('MMMM')} `;
    }
    if (date.year) {
      result += `${Math.abs(date.year)}`;
      if (date.year < 0) {
        result += ' a.C.';
      }
    }
  }
  return result;
}

export function makeHtml(markdown: string, breakpoints: Breakpoint[]) {
  return (
    <ReactMarkdown
      source={markdown}
      plugins={[breaks]}
      escapeHtml={false}
      renderers={{
        code: (props: { language: string; value: string }) => {
          return (
            <SyntaxHighlighter language={props.language} style={syntaxHighlighterStyle}>
              {props.value}
            </SyntaxHighlighter>
          );
        },
        image: (props: { alt: string; title: string; src: string }) => {
          return (
            <Picture
              src={props.src}
              className={'figure-img mb-0'}
              alt={props.alt}
              title={props.title}
              breakpoints={breakpoints}
            />
          );
        },
        table: (props: { columnAlignment: any; children: React.ReactNode }) => {
          return <Table children={props.children} bordered size="sm" responsive />;
        },
        // list: (props: {
        //   start: any;
        //   ordered: boolean;
        //   tight: boolean;
        //   depth: number;
        //   children: React.ReactNode;
        // }) => {
        //   return <ul children={props.children} />;
        // },
        listItem: (props: {
          checked: boolean;
          tight: boolean;
          ordered: boolean;
          index: number;
          children: React.ReactNode;
        }) => {
          return <li className={props.tight ? '' : 'complex'} children={props.children} />;
        },
      }}
    />
  );
}

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
