import _ from 'lodash';
import React from 'react';
import { Container, Figure } from 'react-bootstrap';
import Picture from 'simoneconti-react/src/Picture';
import { makeHtml } from 'simoneconti-react/src/util';
import { json } from './jobInterviewQuestionsJson';
import { breakpoints } from './responsive';

export interface Json {
  sections: Section[];
}

export interface Section {
  title: string;
  articles: Article[];
}

export interface Article {
  title: string;
  rows?: Row[];
}

export interface Row {
  // text?: Text;
  // code?: Code;
  // list?: List;
  // table?: Table;
  image?: Image;
  markdown?: string;
}

export interface Text {
  p: string[];
}

export interface Code {
  language: 'html' | 'css';
  content: string;
}

export interface List {
  title?: string;
  listType: 'ol' | 'ul';
  items: ListItem[];
}

// export interface SubList {
// }

export interface ListItem {
  rows: Row[];
}

export interface Table {
  thead?: string[];
  tbody: string[][];
  tfoot?: string[];
  firstColIsTh?: boolean;
}

export interface Image {
  src: string;
  caption?: string;
  responsive?: boolean;
  webp?: boolean;
  retina?: boolean;
}

const JobInterviewQuestions: React.FC = () => {
  // const location = useLocation();

  // React.useEffect(() => {
  //   if (location.hash === "") {
  //     window.scrollTo(0, 0);
  //   }
  // });

  checkJson(json);

  return (
    <div id="job-interview-questions">
      <Container>
        <nav>
          <ul className="no-padding">
            {json.sections.map((section, index) => (
              <li key={index}>
                <strong>
                  <a href={`#${_.kebabCase(section.title)}`}>{section.title}</a>
                </strong>
                <ul className="no-padding">
                  {section.articles.map((article, index) => (
                    <li key={index}>
                      <A href={!isArticleEmpty(article) ? `#${_.kebabCase(article.title)}` : undefined}>
                        {article.title}
                      </A>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </nav>
        <div>
          {json.sections.map((section, index) => (
            <section key={index} id={_.kebabCase(section.title)} className="href-target">
              <h1>{section.title}</h1>
              {section.articles.map((article, index) => (
                <article
                  key={index}
                  id={_.kebabCase(article.title)}
                  className={`href-target ${isArticleEmpty(article) ? 'd-none' : ''}`}
                >
                  <h4>{article.title}</h4>
                  {article.rows && article.rows.map((row, index) => <Row key={index} row={row} />)}
                </article>
              ))}
            </section>
          ))}
        </div>
      </Container>
    </div>
  );
};

const Row: React.FC<{ row: Row }> = (props) => (
  <>
    {props.row.markdown && makeHtml(props.row.markdown, breakpoints)}
    {/* {props.row.text &&
      props.row.text.p.map((p, index) => (
        <p key={index}>{util.parseHtml(p)}</p>
      ))} */}
    {/* {props.row.code && (
      <SyntaxHighlighter
        language={props.row.code.language}
        style={syntaxHighlighterStyle}
      >
        {props.row.code.content.trim()}
      </SyntaxHighlighter>
    )} */}
    {/* {props.row.list && (
      <>
        {props.row.list.title && <p>{util.parseHtml(props.row.list.title)}</p>}
        {React.createElement(
          props.row.list.listType,
          null,
          props.row.list.items.map((item, index) => (
            <li
              key={index}
              className={`${isListItemComplex(item) ? "complex" : ""}`}
            >
              {item.rows.map((row, index) => (
                <Row key={index} row={row} />
              ))}
            </li>
          ))
        )}
      </>
    )} */}
    {/* {props.row.table && (
      <bs.Table bordered size="sm" responsive>
        {props.row.table.thead && (
          <thead>
            <tr>
              {props.row.table.thead.map((td, index) => (
                <th key={index}>{td}</th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {props.row.table.tbody.map((tr, index) => (
            <tr key={index}>
              {tr.map((td, index) =>
                props.row.table!.firstColIsTh && index === 0 ? (
                  <th key={index}>{td}</th>
                ) : (
                    <td key={index}>{td}</td>
                  )
              )}
            </tr>
          ))}
        </tbody>
        {props.row.table.tfoot && (
          <tfoot>
            <tr>
              {props.row.table.tfoot.map(td => (
                <td>{td}</td>
              ))}
            </tr>
          </tfoot>
        )}
      </bs.Table>
    )} */}
    {props.row.image && (
      <Figure>
        <Picture
          src={props.row.image.src}
          className={`figure-img ${props.row.image.caption ? 'mb-1' : ''}`}
          responsive={props.row.image.responsive}
          webp={props.row.image.webp}
          retina={props.row.image.retina}
          breakpoints={breakpoints}
        />
        {props.row.image.caption && (
          <Figure.Caption className="text-center">{props.row.image.caption}</Figure.Caption>
        )}
      </Figure>
    )}
  </>
);

const A: React.FC<{ href?: string }> = (props) => {
  let properties: any = {};
  if (props.href !== undefined) {
    properties.href = props.href;
  }
  return <a {...properties}>{props.children}</a>;
};

// function isListItemComplex(item: ListItem): boolean {
//   let isComplex = false;
//   item.rows.forEach(row => {
//     if (row.text === undefined && row.list === undefined)
//       isComplex = true;
//   });
//   return isComplex;
// }

function checkJson(json: Json) {
  json.sections.forEach((section) => {
    const title = section.title;
    if (title.endsWith('.')) {
      alert(`${title}\n\nDot not needed.`);
    }
    section.articles.forEach((article) => {
      const title = article.title;
      if (!title.endsWith('.') && !title.endsWith('?')) {
        alert(`${title}\n\nDot needed.`);
      }
      if (title.length !== title.trim().length) {
        alert(`${title}\n\nNot trimmed`);
      }
      article.rows &&
        article.rows.forEach((row) => {
          // if (row.text) {
          //   row.text.p.forEach(p => {
          //     p.split("\n").forEach((line, index, array) => {
          //       if (
          //         line.includes("<br>") ||
          //         line.includes("<br >") ||
          //         line.includes("<br/>")
          //       ) {
          //         // Controlli su tutte le le righe
          //         alert(
          //           `${p}\n\n${line}\n\nLa riga contiene un <br /> scritto male.`
          //         );
          //       }
          //       if (index === 0 || index === array.length - 1) {
          //         // Controlli solo su prima e ultima riga
          //         if (line.length !== 0) {
          //           alert(
          //             `${p}\n\n${line}\n\nLa prima e l'ultima riga deveno essere vuote.`
          //           );
          //         }
          //       } else if (index === array.length - 2) {
          //         // Controlli solo su penultima riga, non deve avere <br />
          //         if (line.endsWith("<br />")) {
          //           alert(
          //             `${p}\n\n${line}\n\nLa penultima riga non deve terminare con <br />.`
          //           );
          //         }
          //       } else {
          //         // Controlli su tutte le righe intermedie
          //         if (line.trim().length === 0) {
          //           alert(`${p}\n\nIl paragrafo contiene una riga vuota.`);
          //         } else if (!line.endsWith("<br />")) {
          //           alert(`${p}\n\n${line}\n\nLa riga non termina con <br />.`);
          //         }
          //       }
          //     });
          //   });
          // }
        });
    });
  });
}

function isArticleEmpty(article: Article) {
  return article.rows && article.rows.length === 0;
}

export default JobInterviewQuestions;
