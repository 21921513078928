import React from 'react';
import Page from './Page';
import { Container, ListGroup } from 'react-bootstrap';
import Query from 'simoneconti-react/src/Query';
import * as rest from 'simoneconti-react/src/rest/rest';
import { EventDto } from 'simoneconti-react/src/interfaces';
import { formatEventDate } from 'simoneconti-react/src/util';

interface Props {}

const EventsPage = (props: Props) => {
  return React.useMemo(
    () => (
      <Page>
        <Container className="px-0 px-sm-3">
          <ListGroup variant="flush">
            <Query<EventDto[]> url={rest.events.build()} method="GET">
              {(dtos) =>
                dtos.map((dto) => (
                  <ListGroup.Item key={dto.id} className="px-sm-0 py-3">
                    <div className="mb-n3">
                      <p className="text-muted mb-0">
                        {formatEventDate(dto.date)}
                        {dto.toDate && <> - {formatEventDate(dto.toDate)}</>}
                      </p>
                      <p className="font-weight-bold">{dto.title}</p>
                      <p>{dto.description}</p>
                    </div>
                  </ListGroup.Item>
                ))
              }
            </Query>
          </ListGroup>
        </Container>
      </Page>
    ),
    [],
  );
};

EventsPage.defaultProps = {};
export default EventsPage;
