import { Json } from './JobInterviewQuestions';

export const json: Json = {
  sections: [
    {
      title: 'Generali',
      articles: [
        {
          title: 'Cos’è e come funziona il Semantic Versioning.',
          rows: [
            {
              markdown: `
E’ un sistema di versionamento che consiste in un insieme di regole che descrivono come un numero di versione debba essere assegnato e/o incrementato.
Data una versione \`MAJOR.MINOR.PATCH\`, gli incrementi riguarderanno:

  - **MAJOR**: Se viene introdotta una modifica alle API non retrocompatibile.
  - **MINOR**: Se viene introdotta una modifica alle API retrocompatibile.
  - **PATCH**: Se viene introdotto un bug-fix retrocompatibile.

Questo sistema nasce con l’obiettivo di risolvere il problema delle dipendenze tra moduli quando questi vengono aggiornati.
`,
            },
          ],
        },
        {
          title: 'Cosa sta a indicare nel Semantic Versioning una versione con Major 0.',
          rows: [
            {
              markdown: `
E’ una versione riservata allo sviluppo iniziale, tutto in questa fase può cambiare in qualsiasi momento.
Le API pubbliche sono quindi da considerarsi non stabili.
`,
            },
          ],
        },
        {
          title: 'Cosa è un Headless CMS.',
          rows: [
            {
              markdown: `
Letteralmente è un CMS senza testa, ovvero senza front-end.
I CMS classici offrono in un unico pacchetto database+backend+frontend, rendendo facile la creazione di siti.
I problemi nascono se si vogliono usare gli stessi contenuti anche su altre piattaforme quali ad esempio App.
Nasce così l’esigenza di separare il backend dal frontend.
Un Headless CMS è un sistema che gestisce dei contenuti e li serve con delle API Rest tramite JSON.
A questo Headless CMS potranno quindi successivamente essere agganciati diversi frontend, quali siti e app, ognuno sviluppato con qualsiasi tecnologia si preferisca.
i CMS più famosi quali Wordpress, Joomla e Drupal hanno a loro volta fatto un passo verso questa direzione integrando nativamente delle API che servono i loro contenuti in JSON, ma continuano ad essere dei CMS monolitici e non vengono considerati Headless, al massimo “Decoupled”.
`,
            },
          ],
        },
      ],
    },
    {
      title: 'Generali (front-end)',
      articles: [
        {
          title:
            'Differenza tra miglioramento progressivo [progressive enhancement] e degradazione elegante [graceful degradation].',
          rows: [
            {
              markdown: `
Sono due differenti approcci per risolvere il problema della piena accessibilità ai contenuti che offriamo, che varia in base all’hardware (es: potenza di calcolo, banda...), al software (browser, sua versione...) e ai vincoli imposti dall’utente (javascript disabilitato...).
La *graceful degradation* è un approccio che prevede di sviluppare il miglior sito possibile, con la possibilità di usare tutte le ultimi feature anche se non ancora pienamente supportate e solo successivamente ci si dedicherà a degradare in maniera elegante la resa sulla base di condizioni meno favorevoli.
Al contrario il *progressive enhancement* parte dalle basi al fine di offrire la migliore user-experience a tutti per poi, via via, arricchire il prodotto all'aumentare delle capacità del sistema e del software utilizzati dal visitatore.
Entrambi gli approcci puntano a realizzare una soluzione cross-browser ma mentre nella graceful degradation "si toglie" qualcosa, nel progressive enhancement "si aggiunge" qualcosa.
`,
            },
          ],
        },
        {
          title: 'Come ottimizzare le risorse/asset di un sito.',
          rows: [
            {
              markdown: `
Comprimendo tutte le immagini in webp e usando il tag introdotto con HTML5 per sfruttare le immagini responsive.

~~~html
<picture>
  <source type="image/webp" media="(min-width: 1200px)" srcset="/images/example/image_xl.webp 1x, /images/example/image_xl@2x.webp 2x" />
  <source type="image/webp" media="(min-width: 992px)" srcset="/images/example/image_lg.webp 1x, /images/example/image_lg@2x.webp 2x" />
  <source type="image/webp" media="(min-width: 768px)" srcset="/images/example/image_md.webp 1x, /images/example/image_md@2x.webp 2x" />
  <source type="image/webp" media="(min-width: 576px)" srcset="/images/example/image_sm.webp 1x, /images/example/image_sm@2x.webp 2x" />
  <source type="image/jpg" media="(min-width: 1200px)" srcset="/images/example/image_xl.jpg 1x, /images/example/image_xl@2x.jpg 2x" />
  <source type="image/jpg" media="(min-width: 992px)" srcset="/images/example/image_lg.jpg 1x, /images/example/image_lg@2x.jpg 2x" />
  <source type="image/jpg" media="(min-width: 768px)" srcset="/images/example/image_md.jpg 1x, /images/example/image_md@2x.jpg 2x" />
  <source type="image/jpg" media="(min-width: 576px)" srcset="/images/example/image_sm.jpg 1x, /images/example/image_sm@2x.jpg 2x "/>
  <img src="/images/example/image_xs.jpg" srcset="/images/example/image_xs.jpg 1x, /images/example/image_xs@2x.jpg 2x" class="img-fluid rounded">
</picture>
~~~

In questo modo possiamo infatti comprimere le immagini in webp avendo comunque un fallback con altri formati compressi quali png e jpg.

Ecco un esempio:
`,
            },
            {
              image: {
                src: 'example/image.jpg',
                caption: 'Credits @ Nintendo',
                responsive: true,
                webp: true,
                retina: true,
              },
            },
          ],
        },
        {
          title: 'Quante risorse scarica per volta un browser da uno specifico dominio.',
          rows: [
            {
              markdown: `
Inizialmente solo 2.
Per questo motivo era solito usare tecniche come il “domain sharding”, ovvero distribuire le immagini su diversi sottodomini per parallelizzare il loro download.
Ad oggi non si usa più in quanto il numero di thread per scaricare asset da un certo dominio è salito e dipende dal browser ma in genere sono circa 6.
Altra tecnica per aggirare il problema può essere quella degli Sprite CSS, ovvero unire più immagini in una sola e poi vedere quella specifica con delle regole css.
`,
            },
          ],
        },
        {
          title:
            'Quali sono i vari modi per diminuire il caricamento della pagina. (percepito o effettivo tempo di caricamento).',
          rows: [
            {
              markdown: `
- Ottimizzare immagini.
- Caching delle risorse.
- Evitare scripts che bloccano il rendering.
  - Mettendoli alla fine del body.
  - Usando l’attributo \`async\`.
- Ridurre le richieste http.
  - Combinando i files js e css.
  - Combinando le immagini con la tecnica dei CSS Sprites.
- Minificazione di js e css.
- Diminuire il “Time to first byte”, ovvero tra la richiesta http e il primo byte ricevuto.
  E’ in sostanza il tempo lato server per preparare una pagina e consiste in:
  - DNS lookup
  - Processamento lato server
- Abilitare Gzip.
- Usare una CDN.
- Caricare i video su servizi di terze parti.
- Caricare prima il contenuto “Above the fold” e successivamente in maniera “lazy” il resto della pagina.
`,
            },
          ],
        },
        {
          title: 'L’importanza degli standard e degli enti di normazione.',
          rows: [
            {
              markdown: `
Negli anni 90 c’era una battaglia tra i 2 principali produttori di browser, Netscape e Microsoft. Ognuno evolveva l’HTML a proprio piacimento, fino ad arrivare ad un punto di collasso, in cui diventarono largamente incompatibili.
I Web Standard sono quindi nati per proteggere questo ecosistema ed è stato chiesto ai creatori dei browser si attenersi a questi standard, cosa che con il tempo è avvenuto, ma non del tutto.
Ci sono tanti modi nel web per ottenere un determinato risultato, ma non tutti godono della stessa compatibilità tra i vari browser. Gli standard web sono un insieme di linee guida che definiscono i migliori approcci per risolvere determinate questioni.
Questi standard inoltre standardizzano l’approccio al problema dell’accessibilità, in modo che gli assistenti quali screen reader possano leggere correttamente i contenuti.
Chi decide quali siano gli standard sono gruppi di persone quali:
- Internet Engineering Task Force (IETF) per i protocolli quali HTTP etc...
- World Wide Web Consortium (W3C) per HTML and CSS.
- ECMA for Javascript.
`,
            },
          ],
        },
        {
          title: 'Cosa è il FOUC e come si evita.',
          rows: [
            {
              markdown: `
Sta per “Flash of unstyled content” e si tratta di un artefatto grafico in cui il sito inizia ad essere visibile (male) per una frazione di secondo ancora prima che gli stili vengano applicati.
Una soluzione comune è quella di mostrare un loader a schermo intero fino a che tutto il sito è pronto e solo a quel punto mostrarlo.
`,
            },
          ],
        },
        {
          title: 'Differenze tra animazioni CSS e animazioni Javascript.',
          rows: [
            {
              markdown: `
In linea generale Javascript andrebbe usato solo quando una determinata cosa non è fattibile né con HTML né con CSS.
Lo stesso discorso vale per le animazioni.
- Le animazioni CSS sono dichiarative, mentre quelle javascript sono imperative.
  Quindi le animazioni css sono predeterminate mentre quelle javascript possono dipendere da fattori esterni.
- Se qualcosa va storto con le animazioni CSS, al massimo un elemento non si anima, mentre con Javascript si potrebbe rompere tutto il motore e rendere il sito inutilizzabile.
- Per la loro natura dichiarativa, le animazioni CSS possono anche essere gestite con l’accelerazione hardware.
- Javascript è generalmente più costoso computazionalmente, meglio usare CSS quando possibile.
`,
            },
          ],
        },
      ],
    },
    {
      title: 'HTML',
      articles: [
        {
          title: 'Cosa fa il doctype.',
          rows: [
            {
              markdown: `
Sta per *Document Type Definition* (dtd) e non è una tag HTML, ma si tratta di un'istruzione indirizzata al browser affinché adotti le giuste regole per il rendering del documento.
Deve essere inserito prima di ogni altra cosa, compreso \`<html>\`.
Con HTML5 è sufficiente inserire \`<!DOCTYPE html>\`, mentre con HTML4 e precedenti bisognava specificare una delle tre opzioni disponibili:

- **Strict**
  ~~~html
  <!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01//EN">
  ~~~
  Il più rigoroso, non permette l’utilizzo di elementi deprecati e di tag per il layout.

- **Transitional**
  ~~~html
  <!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01 Transitional//EN">
  ~~~
  Sono permessi gli elementi deprecati.

- **Frameset**
  ~~~html
  <!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01 Frameset//EN">
  ~~~

Il doctype determinerà quale modalità tra la standard e la quirks verrà utilizzata dal browser.
`,
            },
          ],
        },
        {
          title: 'Cosa sono e differenze tra standard mode e quirks mode.',
          rows: [
            {
              markdown: `
Prima dell’adozione massiva degli standard da parte dei vari browser, i siti venivano creati sfruttando alcune interpretazioni proprie di uno specifico browser, come ad esempio il vertical align o i font-size dentro le tabelle.
Con il tempo tutti i browser hanno iniziato a seguire gli standard ma allo stesso tempo volevano continuare a poter visualizzare bene i siti “legacy”, ovvero creati tenendo a mente le precedenti “deviazioni” dallo standard.
Lo *standard mode* e il *quirks mode* sono quindi 2 modi differenti utilizzati dai browser per renderizzare una pagina web.
Nell *standard mode* una pagina viene renderizzata usando le specifiche standard, al contrario con il *quirks mode* vengono tenute in considerazione una serie di interpretazioni differenti dallo standard.
Un browser decide quale mode utilizzare in base al \`doctype\`.
`,
            },
          ],
        },
        {
          title: 'Differenza tra HTML e XHTML.',
          rows: [
            {
              markdown: `
L'XHTML (eXtensible HyperText Markup Language) è un linguaggio di marcatura che associa alcune proprietà dell'XML con le caratteristiche dell'HTML: un file XHTML è un pagina HTML scritta in conformità con lo standard XML.
HTML5 e XHTML5 condividono lo stesso vocabolario, cosa che non accadeva prima, in quanto XHTML1 conteneva degli elementi non presenti in HTML4.0.1.
HTML5 e XHTML5 vengono processati differentemente: un errore in HTML5 verrà ignorato mentre bloccherà il rendering in XHTML5.
XHTML5 è più “strict” di HTML5, quindi un documento HTML5 fatto bene può anche essere considerato XHTML5.

Alcuni esempi:

- Tag di chiusura obbligatori in XHTML, a volte trascurabili in HTML.
- Abbreviazioni non permesse in XHTML (es: \`checked\` invece di \`checked=checked\`)
- Gli spazi vuoti prima e dopo una stringa vengono del tutto rimossi in XHTML mentre vengono collassati ad un unico spazio vuoto in HTML.
- Il testo deve essere per forza wrappato in XHTML, ad esempio in un \`<p>\`.

Ad oggi lo standard è diventato HTML5, principalmente per 2 motivi:

- HTML5 è più facile e con un parse più permissivo.
- I dispositivi mobili, a cui si rivolgeva XHTML5 essendo più leggero, sono ora molto potenti e leggono facilmente anche HTML5.
`,
            },
          ],
        },
        {
          title: 'Ci sono dei problemi a servire le pagine come application/xhtml+xml?',
          rows: [
            {
              markdown: `
I web server di default servono i file html, php, jsp etc… in formato text/html.
Forzando invece il formato application/xhtml+xml, diciamo al browser di fare il parsing XHTML e di adottare quindi tutti gli standard. Questo comporterà una pagina di errore in caso di non perfetta scrittura della pagina XHTML stessa.
`,
            },
          ],
        },
        {
          title: 'Per cosa sono utili gli attributi data-.',
          rows: [
            {
              markdown: `
Sono degli attributi custom che ci permettono di memorizzare informazioni extra su elementi semantici HTML standard, in genere per poi poter essere utilizzati in scripts.
Queste informazioni saranno poi accessibili con l’attributo \`dataset\`.
Motori di ricerca e screen readers non leggono questi attributi, si dovrebbe quindi limitarne l’utilizzo utilizzo solo per informazioni non importanti.
`,
            },
          ],
        },
        {
          title: 'Principali novità dell’HTML5.',
          rows: [
            {
              markdown: `
- Semplificazione del doctype
- Tag semantici
- Form types e attributi
- Tag multimediali
- Elemento canvas
- Geolocalizzazione
- WebStorage: SessionStorage e LocalStorage
- Drag & Drop
- Script async
`,
            },
          ],
        },
        {
          title: 'Quali sono i tag HTML che usi di più.',
          rows: [
            {
              markdown: `
- header
- nav
- main
- article
- section
- aside
- footer
- hgroup
- picture
`,
            },
          ],
        },
        {
          title: 'Differenze tra cookie, sessionStorage e localStorage.',
          rows: [
            {
              markdown: `
|                           | Cookie                   | SessionStorage                | LocalStorage            |
|---------------------------|--------------------------|-------------------------------|-------------------------|
| **Durata memorizzazione** | Custom in base al maxAge | Fino alla fine della sessione | Fino alla cancellazione |
| **Dimensione**            | Massimo 4KB              | Massimo 5MB                   | La più grande delle tre |
| **Accesso lato server**   | Si                       | No                            | No                      |
| **Traffico**              | Ne fanno parte           | Non ne fanno parte            | Non ne fanno parte      |
| **Contesto**              | Dominio                  | Dominio + Tab                 | Dominio                 |
`,
            },
          ],
        },
        {
          title: 'Differenza tra <script>, <script async> e <script defer>.',
          rows: [
            {
              markdown: `
Le dipendenze Javascript sono per loro natura risorse di blocco, più precisamente parser blocking, quindi al momento in cui il parser del browser inizia la lettura del codice HTML ed incontra uno script, sospende tutte le operazioni di lettura e rendering per poterne scaricare ed eseguire il codice.
Gli script senza gli attributi async o defer, così come gli script in linea, sono quindi presi ed eseguiti immediatamente, prima che il browser continui ad elaborare la pagina.

**Async**
(introdotto in HTML5) se presente indica al browser che deve scaricare gli script in modo asincrono, senza quindi bloccare il parsing del DOM, per bloccarlo invece in fase di esecuzione subito dopo il download. Non ha quindi effetto negli script inline (ad esempio, script che non hanno l'attributo src).
L’esecuzione di uno script async può avvenire in qualsiasi momento, anche prima di DOMContentLoaded.
In presenza di più script async sequenziali viene sempre eseguito prima quello disponibile per primo, ovvero quello il cui download si è rivelato essere più veloce. Di conseguenza l’attributo async non garantisce che lo script venga eseguito nell’ordine in cui si trova all’interno della pagina HTML e quindi non va assolutamente usato per script che richiedono dipendenze non ancora caricate altrimenti il verificarsi di errori di race condition sarà molto alto.
In genere si usa per script esterni totalmente indipendenti e dai quali altri script non dipendono.

**Defer**
(già presente in HTML4) se presente indica al browser che lo script deve essere scaricato in modo asincrono ed eseguito dopo il completamento del parsing del DOM, ma prima dell'evento DOMContentLoaded. Come async, può essere usato solo su script esterni.
A differenza di async, gli script con attributo defer vengono eseguiti rispettando l’ordine in cui sono presenti all’interno della pagina HTML e vengono eseguiti a fine parsing del codice HTML.
Defer è preferibile rispetto a mettere lo script standard a fine DOM in quanto inizierà (e finirà) prima il download in parallelo.

![alt](script-async-defer.png "title")
`,
            },
          ],
        },
        {
          title:
            'Perchè è generalmente una buona idea posizionare CSS <link> tags all’interno di <head> e Javascript <script> tags appena prima di </body>? Conosci eccezioni?',
          rows: [
            {
              markdown: `
Le specifiche HTML4 dicono chiaramente di mettere i css link nell’head, mentre html5 è più permissivo e dice di metterli ovunque.
Rimane tuttavia meglio metterli nell’head per evitare al browser di iniziare il rendering senza gli stili e dover poi fare un nuovo rendering una volta ottenuti.
Alcuni browser, per evitare questo doppio rendering, lo bloccano fino a quando gli stili non saranno scaricati, mostrando intanto una pagina vuota piuttosto che una pagina “in progressivo caricamento”.
Eccezioni per mettere css link fuori dall’head potrebbero essere css abbastanza pesanti che non influiscono troppo sulla resa del sito stesso.
Javascript alla fine di body vengono invece inseriti per evitare di bloccare il parsing del documento.
Metterli ovunque, quindi anche in cima, flaggandoli con defer basterebbe a risolvere il problema (anzi a migliorarlo facendo partire prima il download) se non fosse che alcuni browser non supportano il defer.
`,
            },
          ],
        },
        {
          title: 'Cos’è il rendering progressivo [progressive rendering].',
          rows: [
            {
              markdown: `
E’ una tecnica che prevede di caricare per prima cosa ciò che è essenziale per la fruizione di un contenuto, nel modo più veloce possibile e rimandare a un secondo momento tutto il resto.
Un esempio è il lazy loading delle immagini, dove invece di tenere appesa la richiesta di una pagina fino a quando tutte le immagini non saranno caricate, si preferisce invece caricare ogni singola immagine solo quando questa appare a schermo, anticipandola con un placeholder. Un modo per farlo è spostare la sorgente dell’immagine da \`src\` a \`data-src\` e invertirli tramite javascript solo all’entrata dell’immagine nel viewport.
`,
            },
          ],
        },
      ],
    },
    {
      title: 'CSS',
      articles: [
        {
          title: 'Cosa fa un file CSS “reset” e in cosa è utile.',
          rows: [
            {
              markdown: `
Definisce degli stili base di partenza con lo scopo di uniformare la resa tra i vari browser.
Questi infatti hanno degli stili integrati di default applicati a vari elementi html, come \`margin\`, \`padding\`, \`line-height\`...
Tramite un foglio di reset si appiattiscono queste differenza e si potrà lavorare senza più preoccuparsi delle differenze tra i vari browser.
Uno dei più famosi è quello di Eric Meyer.
`,
            },
          ],
        },
        {
          title: 'Cosa fa un file CSS “normalize” e in cosa è si differenzia da un “reset”.',
          rows: [
            {
              markdown: `
Normalize è un approccio più gentile rispetto al reset.
Ha un approccio meno intrusivo e non resetta tutto ma si limita ad intervenire solo nelle differenze di stile tra i vari browser e rendere quindi lo stile uniforme. Ad esempio mantiene le varie dimensioni dei \`h1 h2 h3 h4 h5 h6\`, a differenza del reset di Meyer che li rende uguali.
Bootstrap usa normalize.
`,
            },
          ],
        },
        {
          title: 'Le varie tecniche di clearing e quale è appropriata per quale contesto.',
          rows: [
            {
              markdown: `
Quando un contenitore ha figli in float, la sua altezza non si baserà su quella dei figli.
Per rimediare ci sono varie tecniche:

- \`float\` al contenitore stesso.
  Difficile da applicare perchè interferisce con il layout.

- \`overflow: auto\` o \`hidden\` al contenitore.
  Con auto potrebbe aggiungere delle barre di scorrimenti non volute, con hidden ha il difetto di rendere invisibili elementi messi fuori dal container, magari tramite un position absolute.

- \`div\` vuoto con \`clear: both\` come ultimo figlio del contenitore.
  Aggiunge elementi che non servono, non è pulito.

- Pseudo classe \`:after\` con \`clear: both\` al contenitore.
  La tecnica preferita, usata anche da bootstrap.
`,
            },
          ],
        },
        {
          title: 'Cosa sono e quali sono le tecniche di sostituzione immagini [image replacement].',
          rows: [
            {
              markdown: `
E’ un modo per sostituire mediante i fogli di stile un’informazione testuale con un’informazione visuale.
Ormai in disuso, queste tecniche servivano quindi per visualizzare immagini ma allo stesso tempo dare ai crawler e agli screen reader dei testi al loro posto.
Il tutto poteva essere fatto con direttamente un’immagine con attributo alt, ma in questo modo il testo era privo di formattazione.

Le varie soluzioni dovevano funzionare tenendo a mente 2 fattori e tutte le possibili combinazioni:

- CSS: on e off
- Immagini: on e off

Nel tempo ci sono state varie idee e proposte sul come ottenere questo risultato, eccone alcune:

- ~~~html
  <h3 id="header">
    <span>Sample Headline</span>
  </h3>
  ~~~
  Dimensioni e background a #header e \`display: none\` allo span.
  Ha il problema del display none allo span, che in alcuni casi viene quindi ignorato e non letto.

- ~~~html
  <h3 id="header">
    <span>Sample Headline</span>
  </h3>
  ~~~
  Dimensioni e background a #header e \`text-indent\` allo span.
  Ottimizzazione della prima e per anni la tecnica più utilizzata, ma si rompe con css on e immagini off.

- ~~~html
  <h3 id="header">
    <span></span>
    Revised Image Replacement
  </h3>
  ~~~
  Background e \`position: absolute\` 100% del padre allo span.
  `,
            },
          ],
        },
        {
          title:
            'Quali sono i vari modi per nascondere visualmente il contenuto (e renderlo disponibile solo per gli screen reader).',
          rows: [
            {
              markdown: `
Non dovrebbe mai servire e in caso contrario potrebbe indicare un cattivo design della pagina. Infatti un contenuto dovrebbe essere presente una sola volta e fruibile sia da utenti convenzionali, sia da screen reader.
Se però dovesse essere necessario presentare un testo dedicato esclusivamente agli screen reader, la tecnica più utilizzata è:

~~~css
.hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
~~~
`,
            },
          ],
        },
        {
          title: 'Come ottimizzare le pagine web per la stampa.',
          rows: [
            {
              markdown: `
Tramite i css. Possiamo infatti definire delle regole css dedicate esclusivamente alla stampa.
Ci sono 2 approcci:

- ~~~html
  <link media="print" href="print.css" />
  ~~~
  (sconsigliato, ci si dimentica di aggiornarlo)

- ~~~css
  @media print {
    // Add print-specific styles here
  }
  ~~~

Ci sono varie ottimizzazioni che si possono fare per ottimizzare una pagina per la stampa:

- Rimuovere elementi inutili per la stampa, come header di navigazione, footer, form...

- Rimuovere colori di sfondo e immagini non significative.

- Aumentare il contrasto del testo, come nero su bianco.

- Aumentare se necessario la dimensione del testo.

- Impostare un margine per la pagina.
  ~~~css
  @page {
    margin: 1cm;
  }
  ~~~

- Gestire gli stili non supportati, la stampa infatti è un po’ come creare template email.

- Usare gli stili \`break-after\`, \`break-before\` e \`break-inside\` per decidere quando interrompere la pagina e iniziarne una nuova.

- Aggiungere contenuto generato tramite css, come ad esempio la destinazione di un <a href="http://www.google.it" target="_blank">link</a> con:
  ~~~css
  a[href]:after {
    content: " [" attr(href) "]";
  }
  ~~~
`,
            },
          ],
        },
        {
          title: 'Vantaggi e svantaggi nell’usare i preprocessori CSS (SASS, LESS...).',
          rows: [
            {
              markdown: `

`,
            },
          ],
        },
        {
          title: 'Come implementare una grafica web che usa font non standard.',
          rows: [],
        },
        {
          title: 'Cos’è e come funziona il box model.',
          rows: [],
        },
        {
          title: 'Perchè nel tempo si è preferito il box-sizing: “border-box” allo standard “content-box”?',
          rows: [],
        },
        {
          title: 'Quando si verifica il collassamento dei margini.',
          rows: [],
        },
        {
          title: 'Come funziona il Cascading Order e quale stile viene preso in presenza di una moltitudine di essi.',
          rows: [],
        },
        {
          title: 'Quali sono le varie unità di misura.',
          rows: [],
        },
      ],
    },
    {
      title: 'JavaScript',
      articles: [
        {
          title: 'Cos’è e come funziona l’event delegation.',
          rows: [],
        },
        {
          title: 'Come funziona l’ereditarietà prototipale [prototypal inheritance].',
          rows: [],
        },
        {
          title: 'Cosa sono CommonJS e AMD e in cosa differiscono.',
          rows: [],
        },
        {
          title: 'Cosa è una hashtable?',
          rows: [],
        },
        {
          title: 'Cosa è un IIFE [Immediately Invoked Function Expression].',
          rows: [],
        },
        {
          title: 'Differenze tra null, undefined e undeclared.',
          rows: [],
        },
        {
          title: 'Cosa è una chiusura [closure], e come/perché viene usata.',
          rows: [],
        },
        {
          title: 'Qual è l’uso tipico di una funzione anonima.',
          rows: [],
        },
        {
          title: 'Spiegazione del “JavaScript module pattern”.',
          rows: [],
        },
        {
          title: 'Differenza tra oggetti host e oggetti nativi.',
          rows: [],
        },
        {
          title: 'Differenza tra: function Person(){}, var person = Person(), e var person = new Person().',
          rows: [],
        },
        {
          title: 'Differenza tra .call e .apply.',
          rows: [],
        },
        {
          title: 'Cosa fa il Function.prototype.bind.',
          rows: [],
        },
        {
          title: 'Come funziona l’ereditarietà in JavaScript.',
          rows: [],
        },
        {
          title:
            'Differenza tra rilevamento di funzionalità [feature detection], deduzione di funzionalità [feature inference], uso della stringa UA e la libreria Modernizr.',
          rows: [],
        },
        {
          title: 'Cos’è AJAX.',
          rows: [],
        },
        {
          title: 'Come funziona JSONP.',
          rows: [],
        },
        {
          title:
            'Cosa è il contesto di esecuzione e perchè posso usare una funziona prima che questa venga dichiarata.',
          rows: [],
        },
        {
          title: 'Cos’è l’hoisting.',
          rows: [],
        },
        {
          title: 'Come funziona l’event bubbling.',
          rows: [],
        },
        {
          title: 'Differenza tra “attribute” e “property”.',
          rows: [],
        },
        {
          title: 'Cosa è l’extending built e quando è meglio usarlo / non usarlo.',
          rows: [],
        },
        {
          title: 'Differenza tra l’evento window load e l’evento document ready.',
          rows: [],
        },
        {
          title: 'Differenza tra == e ===?',
          rows: [],
        },
        {
          title: 'Come prendere il parametro query string dall’URL della finestra del browser.',
          rows: [],
        },
        {
          title: 'Cos’è la policy “stessa origine” [same-origin].',
          rows: [],
        },
        {
          title: 'Come funziona la tecnica della memoizzazione (evitando la ripetizione del calcolo).',
          rows: [],
        },
        {
          title: 'Come impostare dei parametri di default per le funzioni.',
          rows: [],
        },
        {
          title: 'Cosa è \'use strict\' e quali sono i vantaggi e gli svantaggi nell’usarlo.',
          rows: [],
        },
        {
          title: 'Differenza tra Transpiling e Polyfilling.',
          rows: [],
        },
        {
          title: 'Differenza tra module loader e bundler.',
          rows: [],
        },
      ],
    },
    {
      title: 'jQuery',
      articles: [
        {
          title: 'Come, e perché, assegnare un namespace al gestore di un evento.',
          rows: [],
        },
        {
          title: 'Cosa è la concatenazione [chaining].',
          rows: [],
        },
        {
          title: 'Come funziona la coda di effetti [fx queue].',
          rows: [],
        },
        {
          title: 'Differenza tra .eq() e .get().',
          rows: [],
        },
        {
          title: 'Qual’è la differenza tra .bind(), .live(), e .delegate().',
          rows: [],
        },
        {
          title: 'Cosa è $.fn.',
          rows: [],
        },
        {
          title: 'Qual’è la differenza tra $ e $.fn.',
          rows: [],
        },
      ],
    },
    {
      title: 'Accessibilità',
      articles: [
        {
          title: 'Panoramica generale sull’accessibilità.',
          rows: [
            {
              markdown: `
E' la pratica di rendere un sito utilizzabile dal maggior numero di persone possibile, da chi ha problemi di vista chi ha una connessione molto lenta.
In alcuni paesi è un obbligo per legge.
Un sito accessibile è anche implicitamente favorito nei motori di ricerca.
`,
            },
          ],
        },
        {
          title: 'Quali sono i vari tipi di disabilità da tenere in considerazione.',
          rows: [
            {
              markdown: `
- **Deficit visivo**, ovvero persone non vedenti o ipovedenti.
  In questo caso bisogna sviluppare il sito in modo tale che sia compatibile con gli Screen Reader.

- **Deficit uditivo**.
  Sottotitoli ai video, alternative testuali agli audio.

- **Problemi di mobilità**.
  In questi casi la possibilità di usare un mouse viene meno e al suo posto si usano strumenti che possono essere riassunti in un utilizzo con tastiera.
  Bisogna quindi rendere il sito navigabile con pochi tasti, come frecce e tab.

- **Deficit cognitivo**.
  Problemi sulla comprensione e sulla concentrazione.
  Un sito dovrà essere il più semplice possibili, favorendo navigazioni a piccoli steps piuttosto che una lunga pagina complessa, come la registrazione. Il flusso di navigazione logico.
`,
            },
          ],
        },
        {
          title: 'Quali sono alcune delle best-practice per l’accessibilità.',
          rows: [
            {
              markdown: `
- Usare codice semantico, bene interpretato dagli screen reader.
- Usare un linguaggio chiaro, es: “da 5 a 7” invece di “5-7”.
- Costruire un layout di pagina senza tabelle ma usando html5 semantico, evitando i \`div\` in favore di \`nav\`, \`footer\`, \`article\`…
- Dare i giusti nomi specifici alle cose, ad esempio il testo di un link deve far capire dove porta.
  es: “scopri di più sulle balene” invece di “clicca qui”.
- Quando si usano tabelle usare \`thead\`, \`tbody\`, \`caption\`, \`th\`, \`scope\`…
- Usare del testo alternativo per immagini e video, tramite \`alt\` o \`aria-labelledby\`.
`,
            },
          ],
        },
        {
          title: 'Cosa è il WAI-ARIA.',
          rows: [
            {
              markdown: `
La tecnologia WAI-ARIA aggiunge valore semantico a interfacce utente basate su HTML non semantico e aggiornate dinamicamente tramite javascript.
WAI-ARIA è una specifica, cioè una raccolta di indicazioni, prodotta dal W3C, che definisce una serie di attributi HTML addizionali che possono essere applicati agli elementi per fornire maggior valore semantico e migliorare l'accessibilità dovunque sia necessario.
3 caratteristiche principali:

- **Ruoli**
  Definiscono un elemento e la sua funzione.
  \`<nav role="navigation">\`

- **Proprietà**
  Caratteristiche immutabili per dare significati extra agli elementi.
  \`aria-required="true"\`

- **Stati**
  Come le proprietà, ma mutabili tramite javascript.
  \`aria-disabled="true"\`
`,
            },
          ],
        },
        {
          title: 'Dove è supportato il WAI-ARIA.',
          rows: [
            {
              markdown: `
Per poter usare un lettore di schermo ci deve avere installato almeno un browser con la necessaria API di accessibilità, che fornisca ai lettori di schermo le informazioni necessarie perché funzionino.
Il supporto dei browser alla API è ormai mediamente buono (~88%), mentre meno lo è il supporto dei lettori di schermo, anche se sta migliorando nel tempo.
`,
            },
          ],
        },
        {
          title: 'Quando andrebbe usato il WAI-ARIA.',
          rows: [
            {
              markdown: `
Solo quando strettamente necessario.
La migliore soluzione rimane sempre quella di usare HTML5 semantico, ma se ciò non fosse possibile allora il WAI-ARIA è un buon compromesso.
Ad esempio un input type date è nativamente supportato dai lettori di schermo, ma se si preferisce usare un picker javascript, allora questo andrà usato in congiunzione con le direttive ARIA.
`,
            },
          ],
        },
      ],
    },
  ],
};
