import React from 'react';
import Page from './Page';

interface Props {}

const EventPage = (props: Props) => {
  return React.useMemo(() => <Page>EventPage</Page>, []);
};

EventPage.defaultProps = {};
export default EventPage;
