import classNames from 'classnames';
import React from 'react';
import { Form } from 'react-bootstrap';
import * as yup from 'yup';
import { BaseProps, getBaseProps, isFormFieldRequired } from './util';

export interface Props extends BaseProps<HTMLDivElement> {
  name: string;
  label?: string | React.ReactNode;
  help?: string;
  validationSchema?: yup.ObjectSchema;
  noRequiredStyling?: boolean;
}

export const FormGroup = (props: React.PropsWithChildren<Props>) => {
  const { baseProps } = getBaseProps(props, {
    className: classNames({
      required:
        props.validationSchema && !props.noRequiredStyling && isFormFieldRequired(props.name, props.validationSchema),
    }),
  });

  const Label = props.label && <Form.Label>{props.label}</Form.Label>;
  const Help = props.help && <Form.Text className="text-muted">{props.help}</Form.Text>;

  return React.useMemo(
    () => (
      <Form.Group {...baseProps}>
        {Label}
        <div>{props.children}</div>
        {Help}
      </Form.Group>
    ),
    [Help, Label, baseProps, props.children],
  );
};
