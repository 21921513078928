import { useField as _useField, useFormikContext as _useFormikContext } from 'formik';
import { formatDateForInput } from './util';

export { FieldArray } from './FieldArray';
export { Form } from './Form';
export { Checkbox, DateInput, FormField, NumberInput, Radio, Select, TextArea, TextInput } from './FormField';
export { FormGroup } from './FormGroup';

export type formikString = string;
export type formikBoolean = boolean;
export type formikNumber = number | '';
export type formikDate = string;

export const getInitialString = (value?: string): formikString => value ?? '';
export const getInitialBoolean = (value?: boolean): formikBoolean => value ?? false;
export const getInitialNumber = (value?: number): formikNumber => value ?? '';
export const getInitialDate = (value?: Date): formikDate => formatDateForInput(value);

export const useFormikContext = () => _useFormikContext();

export const useField = (name: string) => _useField(name);
