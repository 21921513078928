import React from 'react';
import { Image } from 'react-bootstrap';
import { Breakpoint } from './interfaces';
import { getFileExtension, getFilenameWithoutExtension } from './util';

interface Props {
  src: string;
  alt?: string;
  title?: string;
  className: string;
  id?: string;
  responsive?: boolean;
  webp?: boolean;
  retina?: boolean;
  breakpoints: Breakpoint[];
}

const Picture: React.FC<Props> = (props) => {
  const srcWithoutExtension = getFilenameWithoutExtension(props.src);
  const extension = getFileExtension(props.src);
  const extensions: string[] = [];
  if (props.webp) {
    extensions.push('webp');
  }
  extensions.push(extension);
  let baseImageSuffix = '';
  if (props.responsive) {
    baseImageSuffix = '_xs';
  }
  return (
    <picture id={props.id}>
      {props.responsive &&
        extensions.map((extension) =>
          props.breakpoints
            .reverse()
            .filter((breakpoint) => breakpoint.viewport !== 0)
            .map((breakpoint, index) => (
              <source
                key={index}
                type={`image/${extension}`}
                media={`(min-width: ${breakpoint.viewport}px)`}
                srcSet={getSrcSet(
                  srcWithoutExtension,
                  `_${breakpoint.name}`,
                  extension,
                  props.responsive,
                  props.retina,
                )}
              />
            )),
        )}
      <Image
        src={`https://asset.simoneconti.dev/${srcWithoutExtension}${baseImageSuffix}.${extension}`}
        srcSet={getSrcSet(srcWithoutExtension, baseImageSuffix, extension, props.responsive, props.retina)}
        alt={props.alt}
        title={props.title}
        className={props.className}
        fluid
        rounded
      />
    </picture>
  );
};

function getSrcSet(
  srcWithoutExtension: string,
  suffix: string,
  extension: string,
  responsive?: boolean,
  retina?: boolean,
) {
  if (!responsive && !retina) {
    return undefined;
  } else {
    let srcSet = '';
    const densities = [1, 2];
    densities.forEach((density) => {
      const multiplier = density === 1 ? '' : `@${density}x`;
      srcSet = srcSet + `https://asset.simoneconti.dev/${srcWithoutExtension}${suffix}${multiplier}.${extension} ${density}x, `;
    });
    srcSet = srcSet.trim();
    if (srcSet.endsWith(',')) {
      srcSet = srcSet.substring(0, srcSet.length - 1);
    }
    return srcSet;
  }
}

Picture.defaultProps = {};

export default Picture;
