import classNames from 'classnames';
import React from 'react';
import { Container } from 'react-bootstrap';

interface Props {
  title?: string;
}

const Page = (props: React.PropsWithChildren<Props>) => {
  return React.useMemo(
    () => (
      <div id="main-page" className="pt-4">
        {props.title && (
          <Container>
            <h2 className={classNames('text-center', 'mb-4')}>{props.title}</h2>
          </Container>
        )}
        {props.children}
      </div>
    ),
    [props.children, props.title],
  );
};

Page.defaultProps = {};
export default Page;
