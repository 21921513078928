import React from 'react';
import { Container } from 'react-bootstrap';

const Meals: React.FC = () => {

  return (
    <div id="meals">
      <Container>
        Meals
      </Container>
    </div>
  );
};


export default Meals;
