import _events from './events';
import _session from './session';
import _urls from './urls';
import _books from './books';
import _meals from './meals';

const path = process.env.REACT_APP_REST_BASE_URL as string;

export const build = () => path;

export const session = _session(path);

export const events = _events(path);

export const urls = _urls(path);

export const books = _books(path);

export const meals = _meals(path);