import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileSaver from 'file-saver';
import JSZip from 'jszip';
import _ from 'lodash';
import React from 'react';
import * as bs from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { RangeInput } from 'simoneconti-react/src/FormField';
import {
  Checkbox,
  FieldArray,
  Form,
  FormGroup,
  formikBoolean,
  formikNumber,
  formikString,
  NumberInput,
  TextInput,
} from 'simoneconti-react/src/formik';
import { getFilenameWithoutExtension } from 'simoneconti-react/src/util';
import * as Yup from 'yup';
import Button from './Button';
import Page from './Page';
import { breakpoints } from './responsive';

interface FormValues {
  name: formikString;
  slugifyName: formikBoolean;
  breakpoints: Array<{
    name: formikString;
    width: formikNumber;
    x1: formikBoolean;
    x2: formikBoolean;
    x3: formikBoolean;
  }>;
  densities: {
    x1: formikBoolean;
    x2: formikBoolean;
    x3: formikBoolean;
  };
  types: {
    jpeg: formikBoolean;
    png: formikBoolean;
    webp: formikBoolean;
    gif: formikBoolean;
    bmp: formikBoolean;
  };
  quality: formikNumber;
}

interface ResizedImage {
  image: HTMLImageElement;
  name: string;
  suffix: string;
  density: number;
  type: string;
}

const ImageResizer: React.FC = () => {
  const [file, setFile] = React.useState<File>();
  const [image, setImage] = React.useState<HTMLImageElement>();
  const [resizedImages, setResizedImages] = React.useState<ResizedImage[]>();
  const [isResizing, setIsResizing] = React.useState<boolean>(false);
  const [initialValues, setInitialValues] = React.useState<FormValues>();
  const [defaultPreset, setDefaultPreset] = React.useState<object>();
  const [savedPreset, setSavedPreset] = React.useState<object>(() => initSavedPresetState());

  React.useEffect(() => {
    if (file) {
      const initialValues: FormValues = {
        name: getFilenameWithoutExtension(file.name),
        slugifyName: true,
        breakpoints: breakpoints.map((breakpoint) => ({
          name: breakpoint.name,
          width: breakpoint.container,
          x1: true,
          x2: true,
          x3: false,
        })),
        densities: {
          x1: true,
          x2: true,
          x3: false,
        },
        types: {
          jpeg: file.type.includes('jpeg'),
          png: file.type.includes('png'),
          webp: file.type.includes('webp'),
          gif: file.type.includes('gif'),
          bmp: file.type.includes('bmp'),
        },
        quality: 0.75,
      };
      setDefaultPreset(getFixedValuesForPreset(initialValues));
      setInitialValues({ ...initialValues, ...savedPreset });
    }
  }, [file, savedPreset]);

  return (
    <Page>
      <Container>
        <bs.Form.Group>
          <bs.Form.Label>Image</bs.Form.Label>
          <bs.Form.Control type="file" onChange={handleFileChange} />
        </bs.Form.Group>
        {file && image && initialValues && (
          <>
            <bs.Figure>
              <bs.Figure.Image src={image.src} fluid />
              <bs.Figure.Caption className="text-center">
                {image.width}x{image.height}
              </bs.Figure.Caption>
            </bs.Figure>
            <Form<FormValues>
              validateOnMount
              initialValues={initialValues}
              setInitialTouched
              validationSchema={Yup.object().shape({
                name: Yup.string().required(),
                test1: Yup.boolean(),
                breakpoints: Yup.array().of(
                  Yup.object().shape({
                    name: Yup.string().required(),
                    width: Yup.number()
                      .moreThan(0)
                      .when('x3', {
                        is: true,
                        then: Yup.number().max(image.width / 3),
                        otherwise: Yup.number().when('x2', {
                          is: true,
                          then: Yup.number().max(image.width / 2),
                          otherwise: Yup.number().max(image.width),
                        }),
                      }),
                  }),
                ),
                quality: Yup.number().moreThan(0).max(1),
              })}
              onSubmit={(values, formikHelpers) => {
                setResizedImages(undefined);
                let densities: number[] = [];
                if (values.densities.x1) {
                  densities.push(1);
                }
                if (values.densities.x2) {
                  densities.push(2);
                }
                if (values.densities.x3) {
                  densities.push(3);
                }
                let types: string[] = [];
                Object.entries(values.types).forEach(([type, checked]) => {
                  if (checked) {
                    types.push(type);
                  }
                });
                setIsResizing(true);
                window.setTimeout(() => {
                  getResizedImages(
                    image,
                    values.slugifyName ? _.kebabCase(values.name) : values.name,
                    values.breakpoints,
                    densities,
                    types,
                    values.quality,
                  ).then((resizedImages) => {
                    setResizedImages(resizedImages);
                    formikHelpers.setSubmitting(false);
                    setIsResizing(false);
                  });
                }, 100);
              }}
            >
              {(formik) => (
                <>
                  <p className="text-center">
                    <Button
                      variant="light"
                      onClick={() => {
                        formik.setValues({ ...formik.values, ...defaultPreset });
                      }}
                      disabled={areCurrentValuesDifferentFromPreset(formik.values, defaultPreset)}
                    >
                      Restore default preset
                    </Button>
                    <Button
                      variant="light"
                      className="ml-2"
                      onClick={() => {
                        formik.setValues({ ...formik.values, ...savedPreset });
                      }}
                      disabled={areCurrentValuesDifferentFromPreset(formik.values, savedPreset)}
                    >
                      Load saved preset
                    </Button>
                    <Button
                      variant="light"
                      className="ml-2"
                      onClick={() => {
                        savePreset(formik.values);
                      }}
                      disabled={
                        savedPreset !== undefined && areCurrentValuesDifferentFromPreset(formik.values, savedPreset)
                      }
                    >
                      Save current preset
                    </Button>
                  </p>
                  <FormGroup name="name" label="Name">
                    <TextInput name="name" type="text" />
                  </FormGroup>
                  <FormGroup name="slugifyName" help={_.kebabCase(formik.values.name)}>
                    <Checkbox
                      id="slugifyName"
                      name="slugifyName"
                      label="Slugify name"
                      disabled={formik.values.name === _.kebabCase(formik.values.name)}
                    />
                  </FormGroup>
                  <fieldset>
                    <legend>Breakpoints</legend>
                    <FieldArray
                      name="breakpoints"
                      render={(arrayHelpers) => (
                        <>
                          <bs.Form.Row>
                            <bs.Col xs="6" sm="4">
                              <bs.Form.Label>Name</bs.Form.Label>
                            </bs.Col>
                            <bs.Col xs="6" sm="4">
                              <bs.Form.Label>Width</bs.Form.Label>
                            </bs.Col>
                          </bs.Form.Row>
                          {formik.values.breakpoints &&
                            formik.values.breakpoints.map((breakpoint, index) => (
                              <bs.Form.Row key={index}>
                                <bs.Col xs="6" sm="4">
                                  <TextInput name={`breakpoints.${index}.name`} type="text" />
                                </bs.Col>
                                <bs.Col xs="6" sm="4">
                                  <NumberInput name={`breakpoints.${index}.width`} />
                                </bs.Col>
                                {[1, 2, 3].map((density) => (
                                  <input key={density} type="hidden" name={`breakpoints.${index}.x${density}`} />
                                ))}
                                <bs.Col xs="12" sm="4">
                                  <bs.Form.Group>
                                    <bs.ButtonGroup>
                                      <Button
                                        variant="light"
                                        icon="chevron-up"
                                        onClick={() => arrayHelpers.swap(index, index - 1)}
                                        disabled={index === 0}
                                      />
                                      <Button
                                        variant="light"
                                        icon="chevron-down"
                                        onClick={() => arrayHelpers.swap(index, index + 1)}
                                        disabled={index === formik.values.breakpoints.length - 1}
                                      />
                                      <Button variant="light" icon="times" onClick={() => arrayHelpers.remove(index)} />
                                    </bs.ButtonGroup>
                                  </bs.Form.Group>
                                </bs.Col>
                              </bs.Form.Row>
                            ))}
                          <p>
                            <Button
                              variant="light"
                              icon="plus"
                              onClick={() =>
                                arrayHelpers.push({
                                  name: '',
                                  width: 0,
                                  x1: formik.values.densities.x1,
                                  x2: formik.values.densities.x2,
                                  x3: formik.values.densities.x3,
                                })
                              }
                            >
                              Add breakpoint
                            </Button>
                          </p>
                        </>
                      )}
                    />
                  </fieldset>
                  <bs.Form.Group>
                    <bs.Form.Label className="d-block">Densities</bs.Form.Label>
                    {[1, 2, 3].map((density) => (
                      <Checkbox
                        id={`x${density}`}
                        key={density}
                        label={`@${density}x`}
                        name={`densities.x${density}`}
                        inline
                        onChange={() => {
                          formik.values.breakpoints.map((breakpoint) => {
                            const key = `x${density}`;
                            return ((breakpoint as any)[key] = !(formik.values.densities as any)[key]);
                          });
                          formik.validateForm();
                        }}
                      />
                    ))}
                  </bs.Form.Group>
                  <bs.Form.Group>
                    <bs.Form.Label className="d-block">Types</bs.Form.Label>
                    {['jpeg', 'png', 'webp', 'gif', 'bmp'].map((type) => (
                      <Checkbox id={type} key={type} label={`${type}`} name={`types.${type}`} inline />
                    ))}
                  </bs.Form.Group>
                  <FormGroup
                    name="quality"
                    label="Quality"
                    help={`${Math.round((formik.values.quality as number) * 100).toString()}%`}
                  >
                    <RangeInput name="quality" min={0.01} max={1} step={0.01} />
                  </FormGroup>
                  <bs.FormGroup>
                    <Button type="submit" block disabled={formik.isSubmitting || isResizing}>
                      {isResizing ? 'Resizing...' : 'Resize'}
                    </Button>
                  </bs.FormGroup>
                </>
              )}
            </Form>
          </>
        )}
        {resizedImages && (
          <>
            {resizedImages.length} images -{' '}
            <span className="link" onClick={() => downloadImages(resizedImages)}>
              <FontAwesomeIcon icon="download" />
            </span>
            <ul>
              {Object.entries(_.groupBy(resizedImages, (resizedImage) => resizedImage.type)).map(
                ([type, groupedByType]) => (
                  <li key={type}>
                    <a href={`#type-${type}`}>
                      Type: <strong>{type}</strong> ({groupedByType.length} images)
                    </a>{' '}
                    -{' '}
                    <span
                      className="link"
                      onClick={() => downloadImages(resizedImages.filter((resizedImage) => resizedImage.type === type))}
                    >
                      <FontAwesomeIcon icon="download" />
                    </span>
                    <ul>
                      {groupedByType &&
                        Object.entries(_.groupBy(groupedByType, (resizedImage) => resizedImage.density.toString())).map(
                          ([density, groupedByTypeAndDensity]) => (
                            <li key={density}>
                              <a href={`#type-${type}-density-${density}`}>
                                Density: <strong>@{density}x</strong> ({groupedByTypeAndDensity.length} images)
                              </a>{' '}
                              -{' '}
                              <span
                                className="link"
                                onClick={() =>
                                  downloadImages(
                                    resizedImages.filter(
                                      (resizedImage) =>
                                        resizedImage.type === type && resizedImage.density.toString() === density,
                                    ),
                                  )
                                }
                              >
                                <FontAwesomeIcon icon="download" />
                              </span>
                            </li>
                          ),
                        )}
                    </ul>
                  </li>
                ),
              )}
            </ul>
            {Object.entries(_.groupBy(resizedImages, (resizedImage) => resizedImage.type)).map(
              ([type, groupedByType]) => (
                <div key={type}>
                  <h4 className="mb-1 mt-3">
                    <span className="href-target" id={`type-${type}`}>
                      Type: {type}
                    </span>
                  </h4>
                  {groupedByType &&
                    Object.entries(_.groupBy(groupedByType, (resizedImage) => resizedImage.density.toString())).map(
                      ([density, groupedByTypeAndDensity]) => (
                        <div key={density}>
                          <h5 className="mb-1 mt-2">
                            <span className="href-target" id={`type-${type}-density-${density}`}>
                              Density: {density}
                            </span>
                          </h5>
                          <bs.Form.Row>
                            {groupedByTypeAndDensity.map((resizedImage, index) => (
                              <bs.Col key={index}>
                                <div className="mb-2">
                                  <h6 className="mb-1" style={{ lineHeight: 1 }}>
                                    {resizedImage.name}
                                    <br />
                                    <small className="text-muted">
                                      ({resizedImage.image.width}x{resizedImage.image.height})
                                    </small>
                                  </h6>
                                  <a href={resizedImage.image.src} download={resizedImage.name}>
                                    <bs.Image src={resizedImage.image.src} fluid />
                                  </a>
                                </div>
                              </bs.Col>
                            ))}
                          </bs.Form.Row>
                        </div>
                      ),
                    )}
                </div>
              ),
            )}
          </>
        )}
      </Container>
    </Page>
  );

  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    setResizedImages(undefined);
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      setFile(file);
      const reader = new FileReader();
      reader.onload = function () {
        const img = new Image();
        img.onload = function () {
          setImage(img);
        };
        img.src = reader.result as string;
      };
      reader.readAsDataURL(file);
    } else {
      setFile(undefined);
      setImage(undefined);
    }
  }

  function downloadImages(resizedImages: ResizedImage[]) {
    const zip = new JSZip();
    resizedImages.forEach((resizedImage, index) => {
      zip.file(resizedImage.name, resizedImage.image.src.split('base64,')[1], {
        base64: true,
      });
    });
    zip.generateAsync({ type: 'blob' }).then((content) => {
      FileSaver.saveAs(content, `${file?.name}.zip`);
    });
  }

  function savePreset(values: FormValues) {
    const preset = getFixedValuesForPreset(values);
    window.localStorage.setItem('imageResizerPreset', JSON.stringify(preset));
    setSavedPreset(preset);
  }

  function getFixedValuesForPreset(values: FormValues) {
    const fixed = { ...values };
    delete fixed.name;
    return fixed;
  }

  function initSavedPresetState(): FormValues | undefined {
    const preset = window.localStorage.getItem('imageResizerPreset');
    if (preset) {
      return JSON.parse(preset) as FormValues;
    }
  }

  function areCurrentValuesDifferentFromPreset(values: FormValues, preset?: object) {
    return _.isEqual(values, { ...values, ...preset });
  }

  function getResizedImages(
    image: HTMLImageElement,
    name: string,
    breakpoints: { name: string; width: formikNumber }[],
    densities: number[],
    types: string[],
    quality: formikNumber,
  ) {
    return new Promise<ResizedImage[]>((resolve, reject) => {
      let promises: Promise<ResizedImage>[] = [];
      types.map((type) => {
        return densities.map((density) => {
          return breakpoints.map((breakpoint) => {
            const promise = getResizedImage(image, {
              name: name,
              suffix: breakpoint.name,
              width: breakpoint.width as number,
              density: density,
              type: type,
              quality: quality as number,
            });
            promises.push(promise);
            return promise;
          });
        });
      });
      Promise.all(promises).then((resizedImages) => {
        resolve(resizedImages);
      });
    });
  }

  function getResizedImage(
    image: HTMLImageElement,
    options: {
      name: string;
      width: number;
      suffix: string;
      density: number;
      type: string;
      quality: number;
    },
  ) {
    return new Promise<ResizedImage>((resolve, reject) => {
      const size = options.width * options.density;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
      ctx.drawImage(image!, 0, 0);

      let width = image!.width;
      let height = image!.height;

      if (width > height) {
        if (width > size) {
          height *= size / width;
          width = size;
        }
      }
      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(image!, 0, 0, width, height);
      const dataURL = canvas.toDataURL(`image/${options.type}`, options.quality);

      const resizedImg = new Image();
      resizedImg.onload = function () {
        const resizedImage: ResizedImage = {
          image: resizedImg,
          name: `${options.name}_${options.suffix}${options.density === 1 ? '' : `@${options.density}x`}.${
            options.type
          }`,
          type: options.type,
          density: options.density,
          suffix: options.suffix,
        };
        resolve(resizedImage);
      };
      resizedImg.src = dataURL;
    });
  }
};

export default ImageResizer;
