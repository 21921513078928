import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import EventPage from './EventPage';
import EventsPage from './EventsPage';

interface Props {}

const EventsRouter = (props: Props) => {
  const match = useRouteMatch();
  return React.useMemo(
    () => (
      <Switch>
        <Route path={`${match.path}`} exact>
          <EventsPage />
        </Route>
        <Route path={`${match.path}/:id`} exact>
          <EventPage />
        </Route>
      </Switch>
    ),
    [match.path],
  );
};

EventsRouter.defaultProps = {};
export default EventsRouter;
