import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button as BsButton, ButtonProps } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BaseProps } from 'simoneconti-react/src/util';

export interface Props extends ButtonProps, BaseProps<HTMLButtonElement> {
  icon?: IconName;
  link?: string;
  children?: string;
}

const Button = (props: React.PropsWithChildren<Props>) => {
  const { icon, link, children, ...otherProps } = props;

  const _Button = (
    <BsButton {...otherProps}>
      {icon && <FontAwesomeIcon icon={icon} />}
      {props.children && <span>{props.children}</span>}
    </BsButton>
  );

  return React.useMemo(() => (link ? <Link to={link}>{_Button}</Link> : _Button), [_Button, link]);
};

Button.defaultProps = {};
export default Button;
