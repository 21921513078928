import React from 'react';
import { Container } from 'react-bootstrap';
import { Form } from 'simoneconti-react/src/Form';
import { FormGroup, formikString, getInitialString, TextInput } from 'simoneconti-react/src/formik';
import * as rest from 'simoneconti-react/src/rest/rest';
import { request } from 'simoneconti-react/src/util';
import * as yup from 'yup';
import Button from './Button';
import Page from './Page';

interface Props {}

interface FormValues {
  title: formikString;
  info: formikString;
}

const initialValues: FormValues = {
  title: getInitialString(),
  info: getInitialString(),
};

const validationSchema = yup.object().shape({
  title: yup.string().required(),
});

const DownloadPage = (props: Props) => {
  return React.useMemo(
    () => (
      <Page>
        <Container>
          <Form
            onSubmit={(values, formikHelpers) => {
              request<string[]>({
                method: 'POST',
                url: rest.urls.download.build(),
                data: values,
                formikHelpers: formikHelpers,
              }).then((response) => {
                const urls = response.data;
                for (const url of urls) {
                  window.open(url, '_blank');
                }
              });
            }}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {(formik) => (
              <>
                <FormGroup name="title" label="Titolo" validationSchema={validationSchema}>
                  <TextInput name="title" type="text" />
                </FormGroup>
                <FormGroup name="info" label="Info" validationSchema={validationSchema}>
                  <TextInput name="info" type="text" />
                </FormGroup>
                <Button type="submit">Cerca</Button>
              </>
            )}
          </Form>
        </Container>
      </Page>
    ),
    [],
  );
};

DownloadPage.defaultProps = {};
export default DownloadPage;
