import { dtoId } from '../interfaces';

export default (path: string) => {
  path += '/books';
  return {
    build: () => path,
    book: {
      build: (id: dtoId) => path + '/' + id,
    },
  };
};
