import { dtoId } from '../interfaces';

export default (path: string) => {
  path += '/events';
  return {
    build: () => path,
    event: {
      build: (id: dtoId) => path + '/' + id,
    },
  };
};
